import React from "react";
import InputGroup1 from "../components/InputGroup1.js";
import Turnstile, { useTurnstile } from "react-turnstile";
const ContactUs = () => {
  return (
    <div>
      <header>
        <InputGroup1></InputGroup1>
      </header>
    </div>
  );
};

export default ContactUs;
