import React from 'react';
import Card1 from '../components/Cards/AboutCard'
import Card2 from '../components/Cards/Dashboard'
import Card3 from '../components/Cards/Intelligence'
import Card4 from '../components/Cards/Auto'
import Image from 'react-bootstrap/Image';
import source from '../Images/UsedImages/Depositphotos_312086804_XL.jpg'

const About = () => {
return (
	<div className='scrollAbout'>
		<header className='topText'>
			Aneakot développe des solutions logiciels
			<br></br>
			personnalisées à l`ADN de votre entreprise.
		</header>
		<Image src={source} className='aboutImage'></Image>
		<Card1/>
		<Card2/>
		<Card3/>
		<Card4/>
	</div>
);
};

export default About;
