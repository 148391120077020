import Logo from '../../Images/Aneakot_Logos/Logos_Aneakot/PNG/Aneakot_Logos_RGB_Logo.png'
import React from 'react';
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';

const Navbar = () => {
return (
	<>
	<Nav>
		<Bars />
		<NavMenu>
		<NavLink to='/' >
			<img src= {Logo } alt='' className='logoSize' ></img>
		</NavLink>
		<NavLink to='/about' >
			About
		</NavLink>
		<NavLink to='/services' >
			Services
		</NavLink>
		<NavLink to='/annual' >
			Annual Report
		</NavLink>
		<NavLink to='/team' >
			Teams
		</NavLink>
		<NavLink to='/blogs' >
			Blogs
		</NavLink>
		<NavLink to='/sign-up' >
			Sign Up
		</NavLink>
		{/* Second Nav */}
		{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
		</NavMenu>
		<NavBtn>
		<NavBtnLink to='/Contact-Us'>
			Contact Us
		</NavBtnLink>
		</NavBtn>
	</Nav>
	</>
);
};

export default Navbar;
