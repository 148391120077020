import { useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import Turnstile, { useTurnstile } from "react-turnstile";
function Formscontact() {
  const [validated, setValidated] = useState(false);

  const [FirstName, setFirstname] = useState("");
  const [LastName, setLastname] = useState("");
  const [Phone, setPhone] = useState("");
  const [showSubmit, setSubmitShow] = useState();
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const [Id] = useState("");

  const handlePhoneInput = (e) => {
    setPhone(e.target.value);
  };
  const handleFirstNameInput = (e) => {
    setFirstname(e.target.value);
  };
  const handleLastNameInput = (e) => {
    setLastname(e.target.value);
  };
  const handleEmailInput = (e) => {
    setEmail(e.target.value);
  };
  const handleMessageInput = (e) => {
    setMessage(e.target.value);
  };

  const user = {
    FirstName: FirstName,
    LastName: LastName,
    Phone: Phone,
    Email: Email,
    Message: Message,
  };
  console.log(user);

  const handleSubmitValidation = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    axios.post("https://localhost:7213/api/forms", user).then((res) => {
      console.log(res);
      console.log(res.data);
    });
    event.preventDefault();

    setValidated(true);
  };

  return (
    <div className="forms1">
      <Form noValidate validated={validated} className="formstrue">
        <Col className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              value={FirstName}
              name="firstname"
              onChange={handleFirstNameInput}
              required
              type="text"
              placeholder=""
              defaultValue=""
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          &nbsp;
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              value={LastName}
              name="lastname"
              onChange={handleLastNameInput}
              chaque
              form
              control
              required
              type="text"
              placeholder=""
              defaultValue=""
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>
          &nbsp;
          <Form.Group as={Col} md="4" controlId="validationCustomUsername">
            <Form.Label>Numéro de téléphone</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">+1</InputGroup.Text>
              <Form.Control
                value={Phone}
                name="phone"
                onChange={handlePhoneInput}
                chaque
                form
                control
                type="text"
                placeholder="xxx - xxx - xxxx"
                aria-describedby="inputGroupPrepend"
                required
              />
              <Form.Control.Feedback type="invalid">
                Veuillez entrer un numéro de téléphone
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          &nbsp;
          <Form.Group as={Col} md="4" controlId="validationCustom03">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="example123@example.com"
              required
              value={Email}
              name="email"
              onChange={handleEmailInput}
              chaque
              form
              control
            />
            <Form.Control.Feedback type="invalid">
              Veuillez fournir un e-mail valide.
            </Form.Control.Feedback>
          </Form.Group>
          &nbsp;
          <Form.Group as={Col} md="4" controlId="validationCustom04">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="Message"
              required
              value={Message}
              name="message"
              onChange={handleMessageInput}
              chaque
              form
              control
            />
            <Form.Control.Feedback type="invalid">
              Veuillez fournir un message.
            </Form.Control.Feedback>
          </Form.Group>
          <Turnstile
            sitekey="0x4AAAAAAAIlOwWNFgQANWo1"
            onVerify={(token) => {
              setSubmitShow(token);
            }}
          />
          <div className="submitbut">
            <Button type="submit" onClick={handleSubmitValidation}>
              Soumettre
            </Button>
          </div>
        </Col>
      </Form>
    </div>
  );
}

export default Formscontact;
