import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Pic from '../../Images/UsedImages/ServicesImages/CompositeLayer.png'

function BasicExample() {
  return (
    <Card style={{ width: '18rem' }} className='cardStyle'>
      <Card.Img variant="top" className='dmnPicSize'src = {Pic} />
      <Card.Body className='center'>
        <Card.Title>Input <br></br>
                    Title</Card.Title>
        <Card.Text>
        Input Text 6
        </Card.Text>
        <Button variant="primary" className='avgGradiantBtn'>Go somewhere</Button>
      </Card.Body>
    </Card>
  );
}

export default BasicExample;