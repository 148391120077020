
import Card from 'react-bootstrap/Card';
import Pic from '../../Images/UsedImages/ServicesImages/CompositeLayer.png'

function BasicExample() {
  return (
    <Card style={{ width: '19rem' }} className='cardAuto'>
      <Card.Img variant="top" className='dmnPicSize'src = {Pic} />
      <Card.Body>
        <Card.Title className='centeredTitleText'>Auto et <br></br>
                    Reporting</Card.Title>
        <Card.Text className='centeredBodyText'>
        Création d'un tableau de
        bord avec des visualisations derniers cris pour vous permettre de prendre des décisions juste plus rapidement.
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default BasicExample;