import React from 'react';
import CardMap from '../components/Cards/CardMap'

const Services = () => {
return (
	<div>
	<div className='cardscolumn'>
	<div className='cardsrow'>
		<CardMap/>
	</div>
	<div className='cardsrow'>
		
	</div>
	</div>
	</div>
);
};

export default Services;
