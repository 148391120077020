import Button from 'react-bootstrap/Button';

function TypesExample() {
  return (
    <>
    <a href = '/About' >
      <Button variant="primary" className='avgGradiantBtn'>Commencez!</Button>{' '}
    </a>
    </>
  );
}

export default TypesExample;