import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Services from './pages/services';
import AnnualReport from './pages/annual';
import Teams from './pages/teams';
import Blogs from './pages/blogs';
import SignUp from './pages/signup';
import ContactUs from './pages/contactus';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/services' element={<Services />} />
        <Route path='/annual' element={<AnnualReport />} />
        <Route path='/team' element={<Teams />} />
        <Route path='/blogs' element={<Blogs />} />
        <Route path='/sign-up' element={<SignUp />} />
        <Route path='/contact-us' element={<ContactUs />} />
      </Routes>
    </Router>
  );
}

export default App;
