import { FaBars } from 'react-icons/fa';
import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

export const Nav = styled.nav`
position: fixed;
font-weight: 600;
font-size: 18px;
background: #ffffff;
width: 100%;
height: 65px;
display: flex;
justify-content: space-between;
padding: 0.2rem calc((100vw - 1000px) / 2);
z-index: 12;
/* Third Nav */
/* justify-content: flex-start; */
`;

export const NavLink = styled(Link)`
color: #808080;
display: flex;
align-items: center;
padding: 1rem;
height: 100%;
cursor: pointer;
transition: all .2s ease-in-out;
&:hover {
	transition: all .2s ease-in-out;
	background: #ececec;
	color: #3d7599;
	border-radius: 8px;
}
&.active {
	transition: all .3s ease-in-out;
	background: #ececec;
	border-radius: 8px;
	height: 120%;
	color: #000000;
`;

export const Bars = styled(FaBars)`
display: none;
color: #808080;
@media screen and (max-width: 768px) {
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(-100%, 75%);
	font-size: 1.8rem;
	cursor: pointer;
}
`;

export const NavMenu = styled.div`
display: flex;
align-items: center;
margin-right: -24px;
/* Second Nav */
/* margin-right: 24px; */
/* Third Nav */
/* width: 100vw;
white-space: nowrap; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtn = styled.nav`
display: flex;
align-items: center;
margin-right: 24px;
/* Third Nav */
/* justify-content: flex-end;
width: 100vw; */
@media screen and (max-width: 768px) {
	display: none;
}
`;

export const NavBtnLink = styled(Link)`
border-radius: 5px;
background-image: linear-gradient(45deg, #275ec4,rgb(36, 46, 71));
padding: 17px 15px;
color: #FFFFFF;
transition: all .3s ease-in-out;
/* Second Nav */
&:hover {
	height: 100%;
	transition: all .3s ease-in-out;
    border-color: #94b1e6;
    border-width: 5px;
    background-image: linear-gradient(45deg, #3269d1,rgb(64, 76, 109));  
    color: #ffffff;
    border-radius: 10px;
}
&.active {
	transition: all .3s ease-in-out;
	height: 120%;
	color: #12232e;
`;