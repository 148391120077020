import Button from 'react-bootstrap/Button';

function TypesExample() {
  return (
    <>
    <a href = '/Services'>
      <Button variant="outline-primary" className='homeButton2'>Nos services</Button>{' '}
    </a>
    </>
  );
}

export default TypesExample;