import React from 'react';

const AnnualReport = () => {
return (
	<div className='centeredTextColored'>
	<header>Annual Report</header>
	</div>
);
};

export default AnnualReport;

