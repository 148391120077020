import React from 'react';

const SignUp = () => {
return (
	<div className='centeredTextColored'>
	<header>Sign Up</header>
	</div>
);
};

export default SignUp;
