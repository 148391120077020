import React from 'react';
import Image from '../Images/UsedImages/Moleculestechnologybackground.JPG';
import Button1 from '../components/Button1';
import Button2 from '../components/Button2';

const Home = () => {
return (
	<div className='collumn'>

	<img  className='imageSizeHome'src = {Image} alt='x'/>

	<h1 className='centeredTextWhite'>
        Nous fournissons des <br></br>
		solutions personalisées <br></br>
        pour votre enteprise!<br></br>
        <Button1/>&emsp;&emsp;&emsp;&emsp;<Button2/>
    </h1>
	</div>
);
};

export default Home;