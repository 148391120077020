import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

function BasicExample() {
  return (
    <Card style={{ width: '30rem' }}  className='cardAbout'>
      <Card.Body className='center'>
        <Card.Title><p>&emsp;</p></Card.Title>
        <Card.Text>
        <p>Votre entreprise a des besoins de développement d'applications logiciels mais n'a pas les ressources nécessaire pour y répondre?</p>
        <p>Ne chercher plus! Aneakot est votre solution.</p>
          <p>Que ce soit pour des projets à court ou à long terme, à petite ou à grande échelle, à distance ou à vos bureaux, nous pouvons développer des applications logiciels personnalisées qui répond à tous vos besoins. Le monde des TI est en plein effervescence et nous sommes là pour vous vous aider à réaliser vos plus grands rêves.</p>
        </Card.Text>
        <a href = '/Contact-Us'>
        <Button variant="primary" className='avgGradiantBtn'>Contactez-Nous</Button>
        </a>
      </Card.Body>
    </Card>
  );
}

export default BasicExample;