import React from 'react';

const Blogs = () => {
return (
	<div>
	<h1  className='centeredTextColored'>Blank</h1>
	</div>
);
};

export default Blogs;
