import React from 'react';

const Teams = () => {
return (
	<div className='centeredTextColored'>
	<header>Teams</header>
	</div>
);
};

export default Teams;